import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header activePage="404" color={{ bg: "#fff", text: "#000" }} />
    <div
      className="banner js-viewport-animation"
      data-bg-color="#fff"
      data-color="#000"
    ></div>

    <div className="section404">
      <div className="container404">
        <div className="content js-viewport-animation">
          <div className="sw-logo">
            <svg
              className="pic"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 497.93 154.7"
            >
              <g>
                <g>
                  <path d="M90.93,110.44c0-28.92-86.11-10.3-86.11-65.3C4.82,14.46,33.3,0,60.48,0c30.67,0,58.28,13.59,59.38,47.55H88.09C86.33,32,77.35,27,60.91,27c-9.2,0-22.13,4.38-22.13,15.55,0,26.52,86.12,7.45,86.12,64.2,0,35.06-31.34,48-61.79,48C30.24,154.7.22,138.48,0,102.11H33.3C34.18,119.86,48.2,128,64.42,128,74.72,128,90.93,123.81,90.93,110.44Z" />
                  <path d="M240.15,151.41,227,135c-11,10.52-24.55,18.41-40.1,18.41-26.52,0-48.43-18.63-48.43-45.8,0-16.87,8.55-28.92,21.91-38.35,2.63-2,5.26-3.5,7.89-5.47-7-7.89-12.27-16.88-12.27-27.61C156,14.25,172,0,193.48,0c20.38,0,37.25,13.37,37.25,34.62,0,15.56-10.74,25.2-22.13,33.75L229,93.13q7.89-8.88,15.12-18.41l19.06,19.5a211.74,211.74,0,0,1-16.65,20.38l29.8,36.81ZM185.59,84.8c-6.79,4.6-19.28,13.15-19.28,22.35,0,10.08,9.2,19.5,19.5,19.5,8.76,0,17.53-7.23,23.45-12.92Zm-2.63-50c0,6.36,5,10.74,8.55,15.12,4.82-3.28,12.27-7.89,12.27-14.68,0-6.57-4.6-9.86-10.52-9.86C188.22,25.42,183,28.93,183,34.84Z" />
                  <path d="M430.36,151.47h-39L360.47,47,329.35,151.47h-39l-28.92-149h36.15l17.53,102.76L344.25,2.47h32.21l29.15,102.76L423.13,2.47h36.16Z" />
                  <path d="M481,33.8a16.9,16.9,0,1,1,16.9-16.9A16.92,16.92,0,0,1,481,33.8Zm0-30.66A13.76,13.76,0,1,0,494.79,16.9,13.77,13.77,0,0,0,481,3.14Z" />
                  <path d="M486.26,26.5l-5-8.34h-3.71V26.5H475V6.86h6.46c4.18,0,7.18,1.42,7.18,5.52,0,3-1.85,4.87-4.66,5.52l5.28,8.6Zm-8.7-10.35H481c3.22,0,5-1.23,5-3.77s-1.77-3.51-5-3.51h-3.46Z" />
                </g>
              </g>
            </svg>
          </div>
          <div className="hr js-text-animation"></div>
          <div className="ct">
            <div className="number">404</div>
            <div className="txt txt-cn">抱歉，您查找的页面暂时无法访问！</div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
